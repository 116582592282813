import React from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import getConfig from 'next/config'
import { withRouter } from 'next/router'
import { searchTermAnalytics } from '../utils/analytics'
const { publicRuntimeConfig } = getConfig()

const PER_PAGE = 50
class PredictiveSearch extends React.Component {
  state = {
    isLoading: false,
    options: [],
    query: ''
  };

  _cache = {};

  makeAndHandleRequest (query) {
    const params = new URLSearchParams({
      searchTerm: query,
      store: this.props.store.storenumber || publicRuntimeConfig.DEFAULT_STORE,
      popkey: publicRuntimeConfig.POP_KEY
    })

    return fetch(`${publicRuntimeConfig.APP_ENDPOINT}/api/search/${publicRuntimeConfig.ENV}/suggest?${params.toString()}`,
      {
        headers: {
          deviceid: localStorage.getItem('deviceId') || ''
        }
      })
      .then(res => res.json())
      .then((res) => {
        this.props.setSuggestedProducts(res?.[0]?.searchSuggestions || [])

        if (res.length && res[0].querySuggestions.length) {
          const categoriesOptions = res[0].attributeSuggestions
            ? res[0].attributeSuggestions.filter(x => x.attributeType === 'category').map((i) => ({
              title: i.name,
              cat_id: i.value,
              type: 'category',
              productName: res[0].querySuggestions[0].displayText
            }))
            : []
          let productOptions = res[0].querySuggestions.map((i) => ({
            title: i.displayText,
            type: 'product'
          }))

          productOptions = productOptions.slice(0, 9)

          if (categoriesOptions.length) {
            productOptions.shift()
          }
          const options = categoriesOptions.concat(productOptions)
          this.props.setSuggestedDetails(options)
          const totalCount = options.length
          return { options, totalCount }
        } else {
          const options = []
          const totalCount = 0
          return { options, totalCount }
        }
      })
  }

  componentDidUpdate () {
    this.searchInput.focus()
  }

  render () {
    return (
      <AsyncTypeahead
        {...this.state}
        labelKey="title"
        filterBy={(option, props) => {
          return true
        }}
        maxResults={PER_PAGE - 1}
        minLength={2}
        onInputChange={this._handleInputChange}
        onPaginate={this._handlePagination}
        onSearch={this._handleSearch}
        onKeyDown={(e) => {
          if (e.keyCode === 13 && this.state.query) {
            searchTermAnalytics(this.state.query)
            this.props.router.push(`/search/${this.state.query}`)
            const searchData = {}
            searchData.q = this.state.query
            try {
              window.BrTrk.getTracker().logEvent('suggest', 'submit', searchData, {}, true)
            } catch (e) {
              console.log(e)
            }
          }
        }}
        paginate
        placeholder="What are you looking for?"
        ref={inputEl => (this.searchInput = inputEl)}

        renderMenu={(options, menuProps) => (
          <div />
        )}
        useCache={false}
        searchText=""
        promptText=""
        id="typeahead-search-bar"
      />
    )
  }

  _handleInputChange = (query) => {
    this.props.onChange(query.trim())
    const noTagSqlReg = /<[^>]+>|[^a-zA-Z0-9\s]+/g
    const cleaned = query.replace(noTagSqlReg, '')
    this.setState({ query: cleaned.trim() })
  };

  _handlePagination = (e, shownResults) => {
    const { query } = this.state
    const cachedQuery = this._cache[query]

    // Don't make another request if:
    // - the cached results exceed the shown results
    // - we've already fetched all possible results
    if (
      cachedQuery.options.length > shownResults ||
      cachedQuery.options.length === cachedQuery.total_count
    ) {
      return
    }

    this.setState({ isLoading: true })

    const page = cachedQuery.page + 1

    this.makeAndHandleRequest(query, page).then(res => {
      const options = cachedQuery.options.concat(res.options)
      this._cache[query] = { ...cachedQuery, options, page }
      this.setState({
        isLoading: false,
        options
      })
    })
  };

  _handleSearch = (query) => {
    this.setState({ isLoading: true })
    this.makeAndHandleRequest(query).then(res => {
      this._cache[query] = { ...res, page: 1 }
      this.setState({
        isLoading: false,
        options: res.options
      })
    })
  };
}

export default withRouter(PredictiveSearch)
