interface DataLayerProduct {
  productInfo: {name: string, productID: string};
}

interface WidgetResponse {
  description?: string;
  title?: string;
}

const ONSITE_SEARCH_SUCCEEDED_EVENT = 'Onsite Search Succeeded'
const ONSITE_SEARCH_FAILED_EVENT = 'Onsite Search Failed'
const PAGE_VIEWED_EVENT = 'Page Viewed'
const PRODUCT_VIEWED_EVENT = 'Product Viewed'
const PRODUCT_ADDED_TO_CART_EVENT = 'Product Added to Cart'
const PRODUCT_REMOVED_FROM_CART = 'Product Removed from Cart'
const CART_VIEW_EVENT = 'Cart Viewed'
const USER_SIGNED_IN_EVENT = 'User Signed In'
const USER_SIGNED_OUT_EVENT = 'User Signed Out'
const USER_REGISTERED_EVENT = 'User Registered'
const ORDER_PLACED_EVENT = 'Order Placed'
const NAVIGATION_LINK_CLICKED = 'Navigation Link Clicked'
const PAYMENT_METHOD = {
  6: 'Visa',
  7: 'Master Card',
  8: 'Amex',
  9: 'Debit',
  12: 'Visa Debit',
  13: 'Master Card Debit'
}

const searchTermAnalytics = (searchTerm: string): void => {
  const cleanTerm = searchTerm ? searchTerm.toLowerCase() : ''
  try {
    (window as any)._satellite.track('term-searched', { searchTerm: cleanTerm })
  } catch (e) {
    console.warn(e)
  }
}

const formatF1String = (input: string): string => {
  const normalizedString = input.replace(/[^A-Za-z0-9 ]/g, '')
  return normalizedString.replace(/ /g, '-').toLowerCase()
}

const prepareDigitalData = (): void => {
  if (typeof window === 'undefined') {
    return
  }
  if (typeof (window as any).digitalData === 'undefined') {
    (window as any).digitalData = []
  }
}

const widgetExists = (widgetData: WidgetResponse): boolean => {
  return !!(widgetData?.title && widgetData?.description)
}

const getAnalyticsTargetMessage = (widgetData: WidgetResponse): string | null => {
  return `${widgetData.title}|${widgetData.description}`
}

const getLastPageValues = (): { pageName: string; pageType: string; siteSection: string } => {
  try {
    const lastPageViewEvent = (window as any).digitalData
      .slice()
      .reverse()
      .find((data) => data.event === PAGE_VIEWED_EVENT)

    if (lastPageViewEvent) {
      return {
        pageName: lastPageViewEvent.page.pageInfo.pageName,
        pageType: lastPageViewEvent.page.pageInfo.pageType,
        siteSection: lastPageViewEvent.page.pageInfo.siteSection
      }
    }

    return {
      pageName: '',
      pageType: '',
      siteSection: ''
    }
  } catch (e) {
    console.warn(e)
  }
}

const setPageViewData = (
  pageName: string,
  pageType: string,
  siteSection?: string,
  widgetData?: WidgetResponse,
  currentPoints?: number
): void => {
  try {
    prepareDigitalData()
    const homepageMessage = widgetExists(widgetData) ? getAnalyticsTargetMessage(widgetData) : null
    const dataObj = {
      event: PAGE_VIEWED_EVENT,
      page: {
        pageInfo: {
          pageName,
          pageType,
          ...(siteSection && { siteSection })
        }
      },
      loyalty: {
        ...(homepageMessage && { homepageMessage }),
        currentPoints
      }
    };

    (window as any).digitalData.push(dataObj)
  } catch (e) {
    console.warn(e)
  }
}

const setNavigationLinkClickData = (linkName: string): void => {
  const pageValues = getLastPageValues()
  try {
    const dataObj = {
      event: NAVIGATION_LINK_CLICKED,
      page: {
        pageInfo: {
          pageName: pageValues.pageName,
          pageType: pageValues.pageType,
          ...(pageValues.siteSection && { siteSection: pageValues.siteSection })
        }
      },
      link: {
        linkInfo: {
          linkName
        }
      }
    };

    (window as any).digitalData.push(dataObj)
  } catch (e) {
    console.warn(e)
  }
}

const pushEventOnOrderPlaces = (orderDetail, cartDetail) => {
  try {
    const totalStateFees = orderDetail.TotalStateFees || []
    const OtherFees = totalStateFees.length > 0 ? totalStateFees.reduce((sum, item) => sum + item.totalFeeAmount, 0) : 0
    const stateFeesObj = totalStateFees.length > 0 ? totalStateFees.find((item) => (item.description === 'Shipping' || item.feeId === -5)) : {}
    const dataObj = {
      event: ORDER_PLACED_EVENT,
      transaction: {
        item: cartDetail.products.map((product) => {
          const coupons = product.dealsApplied.coupons || []
          const { quantity } = product
          const sellingPrice = (product.finalPrice / quantity).toFixed(2)
          return {
            price: {
              basePrice: product.originalPrice.toString(),
              sellingPrice: sellingPrice.toString()
            },
            productInfo: {
              name: product.description,
              productID: product.upc.toString(),
              sku: product.sku,
              category: product.category || '',
              brand: product.brand || ''
            },
            quantity,
            voucherDiscount: {
              productLevelCodeAndAmount: coupons.map((x) => `${x.OfferCode}:${x.rewaredOfferValue}`).join('|'),
              productLevelTotalDiscountAmount: coupons.reduce((sum, item) => sum + item.rewaredOfferValue, 0).toString()
            }
          }
        }),
        payment: [
          {
            paymentAmount: orderDetail.Total.toString(),
            paymentID: `${orderDetail.PaymentCard.AuthNumber} | ${orderDetail.PaymentCard.RefNumber}`,
            paymentMethod: PAYMENT_METHOD[orderDetail.PaymentCard.TenderType] || ''
          }
        ],
        purchaseID: orderDetail.OrderID.toString(),
        total: {
          currency: 'USD',
          otherFees: OtherFees.toString(),
          revenue: orderDetail.SubTotal.toString(),
          shippingCost: stateFeesObj.totalFeeAmount ? stateFeesObj.totalFeeAmount.toString() : '',
          shippingVoucherDiscount: 0,
          tax: orderDetail.TotalDeliveryVAT.toString(),
          voucherDiscount: {
            orderLevelCodeAndAmount: '',
            orderLevelTotalDiscountAmount: cartDetail.totalCouponsApplied.toString()
          }
        },
        transactionID: orderDetail.OrderTransactionNumber.toString()
      }
    };
    (window as any).digitalData.push(dataObj)
  } catch (e) {
    console.warn(e)
  }
}

const setSearchResultsData = (searchTerm: string, results: number): void => {
  try {
    const isSuccessful = !!(results > 0)
    const event = isSuccessful ? ONSITE_SEARCH_SUCCEEDED_EVENT : ONSITE_SEARCH_FAILED_EVENT
    const dataObj = {
      event,
      onsiteSearch: {
        keyword: { searchTerm },
        results
      }
    };

    (window as any).digitalData.push(dataObj)
  } catch (e) {
    console.warn(e)
  }
}

const setProductViewData = (products: DataLayerProduct[]): void => {
  try {
    const dataObj = {
      event: PRODUCT_VIEWED_EVENT,
      products
    };

    (window as any).digitalData.push(dataObj)
  } catch (e) {
    console.warn(e)
  }
}

const setCartEventsData = (qty, product, cart) => {
  const cartProduct = cart.products?.find((p) => p.sku === product.sku)
  const cartProductQty = cartProduct ? cartProduct.quantity : 0
  const products = [{
    price: {
      sellingPrice: product.finalPrice
    },
    productInfo: {
      name: product.product_name || product.description,
      productID: product.upc,
      qty: qty === 1 ? cartProductQty + 1 : cartProductQty - 1,
      cost: product.finalPrice || product.price
    }
  }]
  try {
    const productDetails = {
      productID: product.sku,
      productName: product.product_name || product.description
    }
    const dataObj = {
      event: qty === 1 ? PRODUCT_ADDED_TO_CART_EVENT : PRODUCT_REMOVED_FROM_CART,
      products,
      cart: {
        cartID: cart.shoppingListId,
        cartType: 'Cart',
        item: [productDetails]
      }
    };
    (window as any).digitalData.push(dataObj)
  } catch (e) {
    console.warn(e)
  }
}

const setUserLoginLogoutEventData = (isSignInAction = true) => {
  try {
    const dataObj = {
      event: isSignInAction ? USER_SIGNED_IN_EVENT : USER_SIGNED_OUT_EVENT
    };

    (window as any).digitalData.push(dataObj)
  } catch (e) {
    console.warn(e)
  }
}

const setCartViewData = (cart) => {
  try {
    const products = cart.products.map((product) => {
      return {
        productInfo: {
          name: product.description,
          productID: product.upc
        }
      }
    })

    const dataObj = {
      event: CART_VIEW_EVENT,
      cart: {
        cartID: cart.shoppingListId,
        cartType: 'Cart',
        item: [...products],
        total: cart.total
      }
    };
    (window as any).digitalData.push(dataObj)
  } catch (e) {
    console.warn(e)
  }
}

const setUserRegistrationData = (gigyaID) => {
  try {
    const dataObj = {
      event: USER_REGISTERED_EVENT,
      user: {
        userID: gigyaID
      }
    };
    (window as any).digitalData.push(dataObj)
  } catch (e) {
    console.warn(e)
  }
}

export {
  searchTermAnalytics,
  formatF1String,
  setPageViewData,
  setProductViewData,
  setSearchResultsData,
  setCartEventsData,
  setCartViewData,
  setUserLoginLogoutEventData,
  setUserRegistrationData,
  pushEventOnOrderPlaces,
  setNavigationLinkClickData
}
export type { DataLayerProduct }
