/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable func-style */
import { useContext } from 'react'
import { useIonModal } from '@ionic/react'
import getConfig from 'next/config'
import Context from '../context'
import router from 'next/router'
import ServerErrorModal from '../components/ServerErrorModal'

const { publicRuntimeConfig } = getConfig()

type ApiParameters = {
  [key: string]: any;
};

export function useAuthAPI () {
  const { state, dispatch } = useContext(Context)
  const epsilonAPICall = useEpsilonAPI()

  const handleDismiss = () => {
    dismiss()
  }
  const [present, dismiss] = useIonModal(ServerErrorModal, {
    handleDismiss
  })

  const newDeviceErrorArray = [
    'd81be692-d4bf-4e84-8b3c-e158b092a705',
    '9c6120c8-c408-4357-a32f-8a38d42d879d',
    '78cdd73f-8f40-419f-81e1-30054e9e5473'
  ]

  const makeAPICall = async (type: string, endpoint: string, method: string, query: ApiParameters, body: ApiParameters, headers: ApiParameters = {}) => {
    const data = await fetch(`${publicRuntimeConfig.APP_ENDPOINT}/api/${type}/${publicRuntimeConfig.ENV}/${endpoint}?${new URLSearchParams({ popkey: publicRuntimeConfig.POP_KEY, ...query })}`,
      {
        headers: {
          customerid: state.user.UID,
          sessiontoken: state.user.appSessionToken,
          deviceid: state.deviceId,
          ...headers
        },
        method,
        ...(method !== 'GET' && { body: JSON.stringify(body) })
      }
    )
    if (router.asPath.substr(1)?.split('?')[0] === router.query?.redirectedURL) {
      const urlObj = new URL(window.location.href)
      urlObj.searchParams.delete('redirectedURL')
      if (typeof window !== 'undefined') {
        window.history.replaceState(null, '', urlObj.pathname)
      }
    }
    if (data.ok) {
      const res = await data.json()

      if (newDeviceErrorArray.includes(res.error) && router.pathname !== '/login' && router.pathname !== '/registration') {
        router.push('/newdevice')
      }
      return res
    } else {
      const res = await data.json()

      const errorArray = [
        'b419e0e0-af8a-413d-8af2-b3e1f7005193',
        '3bbe99eb-d992-4023-ad6e-f60fe63d8ac0',
        'dec6ff8b-e028-4d06-96f9-4491b48e33c6',
        '8ade7c88-6dc1-461b-a938-3d5855a0da5d',
        '276f5a94-d93f-4503-b280-23691b3c9785',
        '18d37b87-b72f-4f3b-abe3-4dfcff28f9e9',
        'a04bbbd6-1f72-4c0a-8727-1c1e63d18288',
        'f17a2e34-e178-40e5-9ce6-8769374397d2',
        '2548a7f3-7bf4-4533-a6c1-dcbcfcdc26a5',
        '763f1f6a-19db-483f-9d05-68700f7a2b43',
        'b419e0e0-af8a-413d-8af2-b3e1f7005193',
        '287c4bb4-42aa-4be6-a44e-f6325a53dbe5',
        '5d3007d5-0d11-4fc3-b559-813df1151d86'
      ]

      const ignoredErrorArray = [
        'cf3c33dd-e69e-4e94-bbd6-672685d9024c',
        'a487b36e-da31-4676-a6d8-ef3b4492ad88',
        '35860ea6-f094-43c1-9963-5276e3949dd2',
        '5884897b-04f4-42e0-bfd2-bf0bda74c8be',
        '8da13029-2be8-4007-93ef-45e2d5025f15',
        '67d9cf60-b202-4458-8d40-49d102f6bcd3',
        '710017ba-4859-4947-898f-6b6206cc2e5a',
        '85e34c45-91cb-432b-8f92-307938fe0b5f',
        '0630cf0a-9be4-451b-9843-6db9b1ff1928',
        '584db11a-9b74-4ce2-a0e6-1c23a268470d',
        'aeecc5f1-d4d0-4500-9c62-eebe9f242b71',
        '092992f6-94ca-4cdb-8066-6e139fd44543',
        'eca9f7b5-4eee-4c2e-b08c-8448f5bf33ec',
        '724de1e6-667a-4fb9-8140-0b9b483cfbfe',
        'f19f2945-bb3d-44ed-a572-54b236327e70',
        '0630cf0a-9be4-451b-9843-6db9b1ff1928',
        '5b3850ae-6468-4b61-bf01-2b37c54052ec',
        '7d6c9c8a-cd17-434b-bc7e-bb785bae9970',
        '991a6486-3075-4f2b-95b1-1f44cd888971',
        '642025dc-63f8-42d6-8e74-4dd2887fa330'
      ]

      if (newDeviceErrorArray.includes(res.error)) {
        if (endpoint === 'cart' && router.pathname !== '/login' && router.pathname !== '/registration') {
          router.push('/newdevice')
        } else {
          return `/newdevice?redirectedURL=${router.asPath.substr(1)}`
        }
      } else if (errorArray.includes(res.error) && endpoint !== 'anonymous' && endpoint !== 'register') {
        if (state.user.authType) {
          const tokenRes = await fetch(`${publicRuntimeConfig.APP_ENDPOINT}/api/user/${publicRuntimeConfig.ENV}/tokenrefresh?popkey=${publicRuntimeConfig.POP_KEY}`, {
            headers: {
              sessiontoken: state.user.userSessionToken,
              deviceid: state.deviceId
            }
          })
          if (tokenRes.ok) {
            const parsed = await tokenRes.json()
            if (parsed) {
              dispatch({ type: 'USER_CUS_DATA', payload: { ...state.user, appSessionToken: parsed.SessionGUID, userSessionToken: parsed.UserSessionToken } })
              // refresh loyalty data on tokenrefresh scenario
              await epsilonAPICall('epsilon', 'offers/refresh', 'POST', {}, {},
                {
                  'x-pop-loyaltyoffers': publicRuntimeConfig.LOYALTY_OFFERS_KEY,
                  'x-posting-key': state.user.phone,
                  'x-user-id': state.user.UID
                })
              return makeAPICall(type, endpoint, method, query, body, { sessiontoken: parsed.SessionGUID, ...headers })
            } else {
              router.push(`/login?redirectedURL=${router.asPath.substr(1)}`)
            }
          } else {
            if (!router?.query?.redirectedURL) {
              router.push(`/login?redirectedURL=${router.asPath.substr(1)}`)
            }
          }
        } else {
          const data = await makeAPICall('user', 'anonymous', 'POST', {}, {})
          if (!data.appSessionToken || !data.customerGuid) {
            return res
          }
          const user = {
            appSessionToken: data.appSessionToken,
            UID: data.customerGuid,
            authType: 0
          }
          if (state.user?.authType === 1 || state.user?.appSessionToken !== data.appSessionToken || state.user.UID !== data.customerGuid) {
            dispatch({ type: 'USER_CUS_DATA', payload: user })
            return makeAPICall(type, endpoint, method, query, body, { sessiontoken: data.appSessionToken, customerid: data.customerGuid, ...headers })
          }
        }
      } else {
        !ignoredErrorArray.includes(res.error) && router.pathname !== '/' && present()
        return res
      }
    }
  }

  return makeAPICall
}

export function useUnauthAPI () {
  const handleDismiss = () => {
    dismiss()
  }
  const [present, dismiss] = useIonModal(ServerErrorModal, {
    handleDismiss
  })

  const makeAPICall = async (type: string, endpoint: string, method: string, query: ApiParameters, body: ApiParameters) => {
    const data = await fetch(`${publicRuntimeConfig.APP_ENDPOINT}/api/${type}/${publicRuntimeConfig.ENV}/${endpoint}?${new URLSearchParams({ popkey: publicRuntimeConfig.POP_KEY, ...query })}`,
      {
        method,
        ...(method !== 'GET' && { body: JSON.stringify(body) })
      }
    )
    const res = await data.json()
    if (!data.ok) {
      present()
    }
    return res
  }

  return makeAPICall
}

export function useEpsilonAPI () {
  // commenting out for now because the modal is just opening a gray background with no modal
  // during the reg/login flow, perhaps bc of the view changes
  // const handleDismiss = () => {
  //   dismiss()
  // }

  // const [present, dismiss] = useIonModal(ServerErrorModal, {
  //   handleDismiss
  // })

  const makeEpsilonAPICall = async (
    type: string,
    endpoint: string,
    method: string,
    query: ApiParameters,
    body: ApiParameters,
    headers: any
  ) => {
    const data = await fetch(
      `${publicRuntimeConfig.APP_ENDPOINT}/api/${type}/${publicRuntimeConfig.ENV}/${endpoint}?${new URLSearchParams({ ...query })}`,
      {
        method,
        headers,
        ...(method !== 'GET' && { body: JSON.stringify(body) })
      }
    )
    const res = await data.json()
    res.status = data.status
    // if (!data.ok) {
    // present()
    // }
    return res
  }

  return makeEpsilonAPICall
}
