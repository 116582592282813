import React, { useContext, useState } from 'react'
import PredictiveSearch from './PredictiveSearch'
import { searchTermAnalytics } from '../utils/analytics'
import Context from '../context'
import { useRouter } from 'next/router'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Pagination, FreeMode, Navigation } from 'swiper'
import ProductPreviewCard from './ProductPreviewCard'
import RecentSearchResults from './RecentSearchResults'
import SVG from './SVG'

export const Search = ({ handleDismiss }) => {
  const { state } = useContext(Context)
  const [searchTerm, setSearchTerm] = useState('')
  const [details, setDetails] = useState([])
  const [products, setProducts] = useState([])

  const swiperConfig = {
    modules: [FreeMode, Pagination, Navigation, Keyboard],
    freeMode: true,
    pagination: { clickable: true },
    navigation: false,
    spaceBetween: 16,
    keyboard: true,
    watchOverflow: true
  }

  const router = useRouter()
  router.events.on('routeChangeStart', handleDismiss)

  return (
    <div className='search-modal'>
      <div className='search-modal__header'>
        <button className="back-arrow" onClick={handleDismiss}><SVG name="back--white" label="back--white" size="md" /></button>
        <div className="search-modal__predictive-search">
          <PredictiveSearch
            onChange={(e) => setSearchTerm(e)}
            setSuggestedDetails={(data) => setDetails(data)}
            setSuggestedProducts={(data) => setProducts([...data])}
            store={state.cart.store}
          />
          <button
            className="search-modal__predictive-search-button"
            onClick={() => {
              const specialCharReg = /^[^a-zA-Z0-9]+$/
              const cleaned = specialCharReg.test(searchTerm) ? 'default' : searchTerm

              if (cleaned) {
                const noTagSqlReg = /<[^>]+>|[^a-zA-Z0-9\s]+/g
                const finalSearchTerm = cleaned.replace(noTagSqlReg, '')
                searchTermAnalytics(finalSearchTerm)
                router.push(`/search/${searchTerm}`)
                const searchData: { [key: string]: string } = {}
                searchData.q = finalSearchTerm
                try {
                  window.BrTrk.getTracker().logEvent('suggest', 'submit', searchData, {}, true)
                } catch (e) {
                  console.log(e)
                }
              }
            }}
          >
            <SVG name="search" label="search" size="md"></SVG>
          </button>
        </div>
      </div>
      {!!searchTerm && <div className='predictive-search__results'>
        {(!!details.length && !products.length)
          ? <div className="search-results__null">No matches found</div>
          : details.map((result, index) => (
            <div className='suggested-products__container' key={index}>
              {result.type === 'category' && index === 0 &&
                <a href={`/search/${result.productName}`} className="search-results__term" onClick={() => {
                  searchTermAnalytics(searchTerm)
                  const searchData: { [key: string]: string } = {}
                  searchData.aq = searchTerm
                  searchData.q = result.productName
                  try {
                    window.BrTrk.getTracker().logEvent('suggest', 'click', searchData, {}, true)
                  } catch (e) {
                    console.log(e)
                  }
                }}>
                  {result.productName}
                </a>}
              {result.type === 'category' && index < 2 && <a href={`/search/${result.productName}?category=${result.cat_id}`} className="search-results__term search-results__term-child" onClick={() => {
                searchTermAnalytics(searchTerm)
                const searchData: { [key: string]: string } = {}
                searchData.aq = searchTerm
                searchData.q = result.productName
                try {
                  window.BrTrk.getTracker().logEvent('suggest', 'click', searchData, {}, true)
                } catch (e) {
                  console.log(e)
                }
              }} key={result.title}>
                <div className="category-menu-wrapper">
                  <SVG name="arrow-black" label="arrow-black" size="base" />
                </div>
                <div className="category-menu-text">
                  <label className="inner-search-text"> in</label> <span className="search-results__category-name">{result.title}</span>
                </div>
              </a>}
              {result.type === 'product' && <a href={`/search/${result.title}`} className="search-results__term" dangerouslySetInnerHTML={{ __html: result.title.replaceAll(searchTerm, `<span class="search-results__term-highlight">${searchTerm}</span>`) }} onClick={() => {
                searchTermAnalytics(searchTerm)
                const searchData: { [key: string]: string } = {}
                searchData.aq = searchTerm
                searchData.q = result.title
                try {
                  window.BrTrk.getTracker().logEvent('suggest', 'click', searchData, {}, true)
                } catch (e) {
                  console.log(e)
                }
              }} key={result.title}>
              </a>}
            </div>
          ))}
        {products && <div className="search-results__suggestion-wrapper">
          {!!products.length && <p className="search-results__suggestion-title">Suggestions</p>}
          <section className='swiper__section'>
            <Swiper {...swiperConfig} slidesPerView={'auto'}>
              {products.slice(0, 9).map(product => (
                <SwiperSlide key={product.pid}>
                  <ProductPreviewCard data={{ image_urls: product.thumb_image, product_name: product.title, price: product.sale_price, avg_rating: product.avg_rating, slug: product.url.split('/').pop(), upc: product.pid }} />
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
        </div>}
      </div>}
      {!searchTerm && <RecentSearchResults />}
    </div>
  )
}

export default Search
